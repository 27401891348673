/* eslint-disable no-useless-escape */
import React, { FC } from "react";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import { Trans } from "react-i18next";
import { ParagraphLarge, DisplayLarge, HeadingH3 } from "@logrock/pebbles";
import ReactMarkdown from "react-markdown";

import Section from "../Section";
import Container from "../Container";
import Column from "../Column";
import TopBG from "../../images/svg/top-bg.svg";

const TopBackground = styled(TopBG)`
  display: none;
  position: absolute;
  z-index: -1;
  top: 70px;
  right: 0;

  ${up("desktop")} {
    display: block;
  }
`;

const StyledLi = styled.li`
  color: ${({ theme }) => theme.colors.neutral[900]};
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  & + li {
    margin-top: 12px;
  }
`;

interface TermsAndConditionsProps {
  document: {
    slug: string;
    rawBody: string;
  };
}

export const TermsAndConditions: FC<TermsAndConditionsProps> = ({
  document,
}) => {
  return (
    <>
      <Section>
        <Container
          style={{
            marginBottom: 64,
          }}
        >
          <Column center>
            <TopBackground />
            <DisplayLarge
              weight="bold"
              style={{
                marginTop: 93,
              }}
            >
              <Trans>Terms & Conditions</Trans>
            </DisplayLarge>
            <ParagraphLarge
              style={{
                marginTop: 16,
              }}
            >
              <Trans>Last updated April 4, 2023</Trans>
            </ParagraphLarge>
          </Column>
        </Container>

        <Container>
          <Column>
            <ReactMarkdown
              components={{
                h1: (props) => (
                  <HeadingH3
                    {...props}
                    style={{
                      margin: "24px 0px",
                    }}
                  />
                ),
                p: (props) => (
                  <ParagraphLarge
                    {...props}
                    style={{
                      margin: "24px 0px",
                    }}
                  />
                ),
                em: (props) => (
                  <span
                    {...props}
                    style={{
                      textDecoration: "underline",
                      fontStyle: "italic",
                    }}
                  />
                ),
                li: (props) => <StyledLi {...props} />,
              }}
            >
              {document.rawBody}
            </ReactMarkdown>
          </Column>
        </Container>
      </Section>
    </>
  );
};
